import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import Layout from '../layouts';
import { useSiteMetadata } from "../hooks/use-site-metadata";
import Portfolio from '../components/Portfolio';
import SEO from '../components/seo/SEO';
import { postTitle } from '../utils/helpers';
// import he from "he";
export default function PortfolioTemplate( props ) {
  
  const post = props.data.wordpressWpFirewoodPortfolio;
  const site = useSiteMetadata();

  const currentPostTitle = (post.yoast_meta.yoast_wpseo_title) ? post.yoast_meta.yoast_wpseo_title : postTitle({
    site: site.title,
    post: post
  });
  
  return (
    <Layout>
      <Helmet
        bodyAttributes={{ id: `portfolio-${post.slug}`, class: 'portfolio' }}
      >
        <title>{currentPostTitle}</title>
      </Helmet>
      <SEO node={post} site={site} />
      <Portfolio portfolio={post} />
      <section className="section-body no-min-height">
        <div className="content">
          <div className="content--main text-align-center">
            <a href="/work/">
              <button>Back</button>
            </a>
          </div>
        </div>
      </section>
    </Layout>
  );
}

PortfolioTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array
};

export const pageQuery = graphql`
  query($id: String!) {
    wordpressWpFirewoodPortfolio(id: { eq: $id }) {
      ...SEO
      title
      content
      slug
      acf {
        services
        seo_page_title
        seo_page_description
        portfolio_firewood_portfolio {
          __typename
          ... on WordPressAcf_hero {
            image {
              localFile {
                childCloudinaryAsset {
                  fixed {
                    width
                    height
                    src
                  }
                  fluid {
                    src
                    srcSet
                  }
                }
              }
            }
            height
            background_image {
              localFile {
                childCloudinaryAsset {
                  fluid(maxWidth: 1500) {
                    ...CloudinaryAssetFluid
                  }
                }
              }
            }
            background_color
            text_color
            fwm_logo_color
          }
          ... on WordPressAcf_embedded_video {
            internal {
              type
              contentDigest
              owner
            }
            video_url
            fwm_logo_color
          }
          ... on WordPressAcf_image {
            image {
              localFile {
                childCloudinaryAsset {
                  fluid(maxWidth: 1500) {
                    ...CloudinaryAssetFluid
                  }
                }
              }
              alt_text
            }
            fwm_logo_color
          }
        }
        related_projects {
          project {
            post_title
            to_ping
          }
        }
      }
    }
  }
`;
