import React, { useEffect, useContext, useRef } from 'react';
import Img from 'gatsby-image';
import { HeaderContext } from '../context/HeaderContext';

function Portfolio(props) {
  const ctxRef =  useRef(useContext(HeaderContext));

  useEffect(() => {
    const portfolio = props.portfolio;
    const context = ctxRef;
    
    portfolio.acf.portfolio_firewood_portfolio.forEach(layout => {
      if (
        layout.__typename === `WordPressAcf_hero` &&
        layout.fwm_logo_color === 'light'
      ) {
        context.current.resetLogoLight();
      }
    });
    return function cleanup() {
      context.current.resetLogoDark();
    }
  }, [props.portfolio]);

  
  const portfolio =   props.portfolio;
  return (
    portfolio.acf.portfolio_firewood_portfolio !== null &&
    portfolio.acf.portfolio_firewood_portfolio.map((layout, i) => {
      if (layout.__typename === `WordPressAcf_hero`) {
        const backgroundImage =
          layout.background_image.localFile.childCloudinaryAsset.fluid;
        const backgroundColor = layout.background_color;
        const image = layout.image;
        const className =
          'section-full ' + layout.height + ' ' + layout.text_color;

        return [
          <section
            key={i}
            className={className}
            style={{ backgroundColor: backgroundColor }}
          >
            {backgroundImage && (
              <div className="background-image">
                <Img fluid={backgroundImage} />
              </div>
            )}

            <div className="content">
              <div className="content--main">
                <img
                  src={image.localFile.childCloudinaryAsset.fluid.src}
                  className="image-responsive"
                  width={image.localFile.childCloudinaryAsset.fixed.width}
                  height={image.localFile.childCloudinaryAsset.fixed.height}
                  alt={image.alt_text}
                />
              </div>
            </div>
            <div
              id="project-info"
              style={{ backgroundColor: backgroundColor }}
            >
              <div className="project-info--content">
                <div className="project-info--main">
                  <div className="project-details">
                    <p className="eyebrow">Client</p>
                    <span
                      dangerouslySetInnerHTML={{ __html: portfolio.title }}
                    />
                  </div>
                  <div className="project-details">
                    <p className="eyebrow">Services</p>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: portfolio.acf.services
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>,
          <section key={'section-body' + i} className="section-body">
            <div className="content">
              <div
                className="content--main"
                dangerouslySetInnerHTML={{ __html: portfolio.content }}
              />
            </div>
          </section>
        ];
      }

      if (
        layout.__typename === `WordPressAcf_image` ||
        layout.__typename === `WordPressAcf_embedded_video`
      ) {
        const image = layout.image;
        const className =
          layout.__typename === `WordPressAcf_embedded_video`
            ? 'section-full video'
            : 'section-full image';

        return (
          <section key={i} className={className}>
            {layout.__typename === `WordPressAcf_image` && (
              <Img
                fluid={image.localFile.childCloudinaryAsset.fluid}
                className="gatsby-image-relative"
                alt={image.alt_text}
              />
            )}
            {layout.__typename === `WordPressAcf_embedded_video` && (
              <div
                className="embed-container"
                dangerouslySetInnerHTML={{ __html: layout.video_url }}
              />
            )}
          </section>
        );
      }

      return null;
    })
  );
  
}

export default Portfolio;
